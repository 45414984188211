@import './materials.module.scss';

.loginForm { 
    margin: auto;
    text-align: center;
    direction: ltr;
    padding-bottom: 100px;
    input {
        display: block;
        margin: 0 auto;
    }
    button {
        margin-top: 20px;
        width: 100%;
    }
}

.footerAdmin {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    text-align: center;
    padding: 10px 20px;

    button {
        cursor: pointer;
        font-size: 18px;
        font-weight: 700;
        display: block;
        background-color: bisque;
        padding: 10px 20px;
        width: 300px;
        margin: 0 auto;
        margin-top: 10px;
        border-radius: $borderRadius;
    }
}