* {
  box-sizing: border-box;
  font-family: 'Assistant', sans-serif;
}
body {
  margin: 0;
  padding: 0;
  background-color: #FCFCFC;
  direction: rtl;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
ul {
  padding: 0;
  margin: 0;
  list-style: none;
}