@import './materials.module.scss';

.itemContainer {
    margin: 0 auto;
    max-width: 400px;
    position: relative;
    margin-top: 30px;
    span {
        position: absolute;
        bottom: 10px;
        z-index: 10;
        color: $white;
        display: block;
        font-size: 28px;
        font-weight: 800;
        padding: 20px;
        &::after {
            content: '';
            height: 26px;
            margin-right: 10px;
            width: 3px;
            background-color: $starColor;
            z-index: 10;
            position: absolute;
            top: 54%;
            transform: translateY(-50%);
        }
    }

    img {
        display: block;
        
        width: 100%;
        border: 3px solid $white;
        border-radius: $borderRadius;
        box-shadow: 0 3px 14px rgba(0,0,0,0.05);
    }
}

.toggleItem {
    max-width: 60px;
    height: 30px;
    border-radius: 50px;
    margin-left: 10px;
    border: 2px solid #fff;
    cursor: pointer;
    background-color: brown;
    position: relative;

    &::after {
        content: '';
        width: 20px;
        height: 20px;
        position: absolute;
        background-color: #fff;
        top: 3px;
        left: 3px;
        border-radius: 50%;

    }
}

.adminGridItem {
    display: inline-block;
    max-width: 320px;
    position: relative;
    direction: rtl;
    margin: 0 auto;
    margin-top: 30px;
    
    span {
        position: absolute;
        bottom: 10px;
        right: 0;
        z-index: 10;
        color: $white;
        display: block;
        font-size: 28px;
        font-weight: 800;
        padding: 20px;
    }

    img {
        display: block;
        filter: grayscale(100%);
        opacity: .5;
        width: 100%;
        border: 3px solid $white;
        border-radius: $borderRadius;
        box-shadow: 0 3px 14px rgba(0,0,0,0.05);
    }
}

.adminGridItem.available {
    
    .toggleItem {  
        background-color: green;

        &::after {
            content: '';
            width: 20px;
            height: 20px;
            position: absolute;
            background-color: #fff;
            top: 3px;
            right: 3px;
            border-radius: 50%;
    
        }
    }

    img {
        opacity: 1;
        filter: grayscale(0);
    }
}