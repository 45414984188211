@import './materials.module.scss';

.messageFooter { 
    width: 100%;
    display: block;
    // padding: 20px 30px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
}

.phoneCall {
    display: block;
    max-width: 300px;
    margin: 20px auto;
    border-radius: 4px;
    text-align: center;
    font-size: 20px;
    font-weight: 800;
    padding: 10px 20px;
    letter-spacing: 6px;
    color: $white;
    text-decoration: none;
    background-color: darkcyan;

}