@import './materials.module.scss';

.date {
    font-size: 18px;
    font-weight: 700;
    display: block;
    background-color: bisque;
    padding: 10px 20px;
    width: 300px;
    margin: 0 auto;
    margin-top: 10px;
    border-radius: $borderRadius;
}

.importantContent {
    display: block;
    max-width: 500px;
    margin: 10px auto;
    font-weight: 600;
}