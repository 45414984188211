@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;500;600;700;800&display=swap');
@import './materials.module.scss';

.container {
   width: 100%;
   max-width: 1440px;
   padding: 0 20px; 
   margin: 0 auto;
   margin-bottom: 200px;
}

.header {
   width: 100%;
}

.headerDescription {
   text-align: center;
   span {
      display: block;
   }
}


.logoContainer {
   img {
      width: 120px;
   }
}


.saparatorVerrtical {
   width: 100%;
   background-color: $grayBorder;
   height: 1px;
   margin: 20px auto;
}

.flexCenter {
   display: flex;
   align-items: center;
   justify-content: center;
   flex-wrap: wrap;
}

.footer {
   position: fixed;
   bottom: 0;
   right: 0;
   left: 0;
   border-top: 1px solid #e3e3e3;
   background-color: white;
   z-index: 100;
   box-shadow: 0 -10px 30px rgba(255,255,255, 1);
   text-align: center;
}
.wrapperCenterd {
   display: flex;
   height: 100vh;
}

.formGroup {
   margin-top: 10px;
}

.loading {
   display: block;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   font-size: 38px;
   font-weight: 800;
   color: darkcyan;
   text-align: center;
}